import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ContactDetail = () => {
  const { id } = useParams(); // Obtener el id de la URL
  const [contact, setContact] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
  
    if (token) {

        fetch(`https://apis.qrtsolutions.com/users-api/clients/contact/${id}`, {

        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log("Datos del contacto:", data); // Muestra los datos en la consola
          setContact(data);
        })
        .catch(error => console.error('Error al obtener los detalles del contacto:', error));
    } else {
      window.location.href = '/login';
    }
  }, [id]);
  

  if (!contact) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
  <h1 className="text-3xl font-bold text-gray-800 mb-4 border-b pb-2 text-center">
    {contact.name || "Sin nombre"}
  </h1>
  <div className="overflow-x-auto">
    <table className="w-full">
      <tbody>
        <tr className="border-b">
          <td className="px-4 py-3 font-semibold text-gray-900">Email:</td>
          <td className="px-4 py-3 text-gray-700">{contact.email || "Sin email"}</td>
        </tr>
        <tr className="border-b">
          <td className="px-4 py-3 font-semibold text-gray-900">Teléfono:</td>
          <td className="px-4 py-3 text-gray-700">{contact.phone || "Sin teléfono"}</td>
        </tr>
        <tr className="border-b">
          <td className="px-4 py-3 font-semibold text-gray-900">Empresa:</td>
          <td className="px-4 py-3 text-gray-700">{contact.company || "Sin empresa"}</td>
        </tr>
        <tr className="border-b">
          <td className="px-4 py-3 font-semibold text-gray-900">Tipo de Consulta:</td>
          <td className="px-4 py-3 text-gray-700">{contact.query_type || "Sin tipo de consulta"}</td>
        </tr>
        <tr>
          <td className="px-4 py-3 font-semibold text-gray-900">Mensaje:</td>
          <td className="px-4 py-3 text-gray-700">{contact.message || "Sin mensaje"}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


  );
  
};

export default ContactDetail;
