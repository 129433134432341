import dataDriven from '../images/nosotros/data-driven.png'
import circulo1 from '../images/nosotros/circulo1.png'
import circulo2 from '../images/nosotros/circulo2.png'
import circulo3 from '../images/nosotros/circulo3.png'
import circulo4 from '../images/nosotros/circulo4.png'
import mision from '../images/nosotros/mision.gif'
import vision from '../images/nosotros/vision.gif'


const Somos = () =>{
    return(
        <div className='flex flex-col flex-1'>
            <div className="w-screen flex justify-center bg-white h-auto">
                <div className="w-full lg:w-5/6 p-8 rounded-lg text-center bg-white">
                    <h1 className="text-5xl font-bold mb-4 text-black">
                        Somos tu <br />
                        <span className="bg-blue-500 px-2 py-0 rounded-md text-white inline-block">
                            Partner Estratégico
                        </span>
                    </h1>
                    <p className="text-zinc-900 leading-7 md:leading-8 mt-8">
                    Más que un aliado tecnológico, somos un partner comprometido con tu éxito. Nos apasiona diseñar soluciones tecnológicas que van más allá de lo convencional. Nuestro enfoque es hiper-personalizado y creativo: analizamos cada detalle de tus operaciones para descubrir oportunidades únicas y desarrollar estrategias basadas en IA que potencien tu crecimiento. Con QRT, no solo optimizas procesos; transformas tu negocio y lideras en innovación.
                    </p>
                </div>
            </div>

            
            <div className="p-0 flex flex-col items-center h-[700px] w-full -mt-28 sm:mt-0" 
                style={{ backgroundImage: `url(${dataDriven})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat',  backgroundPosition: 'center' }}>
            </div>
        
            <div className="max-w-7xl mx-auto sm:mt-40 p-5 grid gap-y-36 sm:gap-y-36 lg:gap-y-8 gap-x-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
                <section className="rounded-t-lg p-8 relative bg-slate-50">
                    <img src={circulo3} alt="Personalización Innovadora" className="absolute top-[-100px] left-1/2 -translate-x-1/2 w-52 h-52 rounded-full border-8 bg-slate-50 border-slate-50" />
                    <div className="pt-20">
                        <h2 className="text-blue-500 text-center font-bebas p-3" style={{fontSize: '23px', fontWeight: '100'}}>PERSONALIZACIÓN INNOVADORA</h2>
                        <p className="text-gray-600 mt-2 text-center text-xs sm:text-sm">Creamos soluciones a la medida de cada cliente, asegurando que cada proyecto refleje las necesidades únicas y objetivos específicos, como un traje hecho a medida.</p>
                    </div>
                </section>

                <section className="rounded-t-lg p-8 relative bg-slate-50">
                    <img src={circulo1} alt="Integración Perfecta" className="absolute top-[-100px] left-1/2 -translate-x-1/2 w-52 h-52 rounded-full border-8 bg-slate-50 border-slate-50" />
                    <div className="pt-20">
                        <h2 className="text-blue-500 text-center font-bebas px-8 py-3" style={{fontSize: '23px', fontWeight: '100'}}>INTEGRACIÓN PERFECTA</h2>
                        <p className="text-gray-600 mt-2 text-center text-xs sm:text-sm">No solo resolvemos problemas a través de la tecnología, sino que la integramos de manera fluida en los procesos existentes, potenciando el conocimiento y la experiencia de las personas.</p>
                    </div>
                </section>

                <section className="rounded-t-lg p-8 relative bg-slate-50">
                    <img src={circulo4} alt="Compromiso con la Excelencia" className="absolute top-[-100px] left-1/2 -translate-x-1/2 w-52 h-52 rounded-full border-8 bg-slate-50 border-slate-50" />
                    <div className="pt-20">
                        <h2 className="text-blue-500 text-center font-bebas p-3" style={{fontSize: '23px', fontWeight: '100'}}>COMPROMISO CON LA EXCELENCIA</h2>
                        <p className="text-gray-600 mt-2 text-center text-xs sm:text-sm">Nos enfocamos en la mejora continua, trabajando incansablemente para elevar los estándares de calidad y superar las expectativas de nuestros clientes.</p>
                    </div>
                </section>

                <section className="rounded-t-lg p-8 relative bg-slate-50">
                    <img src={circulo2} alt="Agilidad y Adaptabilidad" className="absolute top-[-100px] left-1/2 -translate-x-1/2 w-52 h-52 rounded-full border-8 bg-slate-50 border-slate-50" />
                    <div className="pt-20">
                        <h2 className="text-blue-500 text-center font-bebas px-5 py-3" style={{fontSize: '23px', fontWeight: '100'}}>AGILIDAD Y ADAPTABILIDAD</h2>
                        <p className="text-gray-600 mt-2 text-center text-xs sm:text-sm">Nos mantenemos a la vanguardia, anticipando cambios y adaptándonos rápidamente para asegurar que nuestros clientes siempre estén un paso adelante en un mundo en constante evolución.</p>
                    </div>
                </section>
            </div>

            <div class="max-w-7xl mx-auto p-6 md:flex justify-between gap-8">
                <div class="w-full md:w-1/2 px-4 mb-8 md:mb-0 flex flex-col items-center">
                    <img src={vision} alt="Visión" class="w-32 h-32 mb-4 mx-auto"/>
                    <h2 class="text-blue-500 pb-2 text-center font-bebas" style={{fontSize: '40px', fontWeight: '100'}}>VISIÓN</h2>
                    <p class="text-black text-lg mt-4 text-center">
                        Nuestro compromiso es con la excelencia y la innovación; soñamos con un mundo donde la tecnología no solo facilite, sino también inspire, permitiendo a las empresas alcanzar nuevas alturas de creatividad y eficiencia.
                    </p>
                </div>

                <div class="w-full md:w-1/2 px-4 flex flex-col items-center">
                    <img src={mision} alt="Misión" class="w-32 h-32 mb-4 mx-auto"/>
                    <h2 class="text-blue-500 pb-2 text-center font-bebas" style={{fontSize: '40px', fontWeight: '100'}}>MISIÓN</h2>
                    <p class="text-black text-lg mt-4 text-center">
                        Nos dedicamos con pasión y entusiasmo a entender y anticipar las necesidades de nuestros clientes, convirtiendo cada desafío en una oportunidad para crear algo extraordinario.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Somos;