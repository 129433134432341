import React from 'react';
import { Link } from 'react-router-dom';
import aboutusImg from '../images/about_us_bg.svg';
import agilidad from "../images/gifs/onlinegiftools2.gif";
import personalizacion from "../images/gifs/onlinegiftools1.gif";
import compromiso from "../images/gifs/onlinegiftools3.gif";
import integracion from "../images/gifs/onlinegiftools4.gif";

const AboutUs = () => {
  return (
    <div
      className="relative bg-cover bg-center text-white"
      style={{ backgroundImage: `url(${aboutusImg})` }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 max-w-6xl mx-auto py-16 px-4 text-center">
        <h2 className="text-xs md:text-sm uppercase tracking-widest mb-2 font-bold">02 | Nosotros</h2>
        <h1 className="text-3xl md:text-4xl font-bold mb-4 leading-">
          Tecnología que <br />
          <span className="">Inspira, </span><span className='bg-yellow-500 rounded-lg text-black px-2 font-black py-0' style={{ height: '0.99em', display: 'inline-block'}}> Soluciones</span> <br />
          <span className='bg-yellow-500 rounded-lg text-black font-black px-2 py-0' style={{ height: '0.99em', display: 'inline-block'}}>que Transforman.</span>
        </h1>
        <p className="text-xs md:text-lg mb-8">
        Ofrecemos consultoría en transformación tecnológica basada en IA para ayudar a las empresas a prosperar en la era digital. Vemos oportunidades que otros no ven, diseñando estrategias personalizadas que integran tecnología avanzada para optimizar procesos, mejorar la eficiencia y llevar a las empresas a la vanguardia de sus industrias.
        </p>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 mb-8">
  <div className="flex flex-row items-center md:flex-row font-bebas">
    <img
      src={personalizacion}
      alt="Personalización Innovadora"
      className="mb-2 h-20 w-20 md:h-32 md:w-32"
    />
    <span className="ml-2 text-xs md:text-xl text-left md:text-center">PERSONALIZACIÓN<br/> INNOVADORA</span>
  </div>
  <div className="flex flex-row items-center md:flex-row font-bebas">
    <img
      src={compromiso}
      alt="Compromiso con la Excelencia"
      className="mb-2 h-20 w-20 md:h-32 md:w-32"
    />
    <span className="ml-2 text-xs md:text-xl text-left md:text-center">COMPROMISO CON <br/>LA EXCELENCIA</span>
  </div>
  <div className="flex flex-row items-center md:flex-row font-bebas">
    <img 
      src={agilidad} 
      alt="Agilidad y Adaptabilidad" 
      className="mb-2 h-20 w-20 md:h-32 md:w-32" 
    />
    <span className="ml-2 text-xs md:text-xl text-left md:text-center">AGILIDAD Y <br/>ADAPTABILIDAD</span>
  </div>
  <div className="flex flex-row items-center md:flex-row font-bebas">
    <img 
      src={integracion} 
      alt="Integración Perfecta" 
      className="mb-2 h-20 w-20 md:h-32 md:w-32" 
    />
    <span className="ml-2 text-xs md:text-xl text-left md:text-center">INTEGRACIÓN<br/> PERFECTA</span>
  </div>
</div>
        <Link to="/nosotros">
  <button className="bg-yellow-500 text-black py-2 px-4 rounded-2xl w-64">Ver Más</button>
</Link>
      </div>
    </div>
  );
};

export default AboutUs;
