import React from 'react'
import rightImage from "../images/datavision_right.svg"
import leftImage from "../images/datavision_left.svg"


const DataVision = () => {
  return (
    <div className="max-w-7xl w-[98%] md:w-2/3 mx-auto p-4">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4  rounded-lg p-4">
    <div className="flex justify-center md:order-last order-first">
      <img src={rightImage} alt="Datavision BI Image" className="rounded-lg"/>
    </div>
      <div className="flex flex-col items-center sm:items-start md:order-first order-last">
        <h2 className="text-3xl font-bold bg-blue-700 text-white px-8 leading-6 py-1 rounded-xl">Datavision BI</h2>
        <p className="sm:mt-2 mt-6 text-black text-xs sm:text-base leading-4 sm:leading-8 text-center sm:text-justify">
          La inteligencia de negocios (BI) es fundamental para la <span className="hidden sm:inline-block sm:font-bold">toma de decisiones informadas</span><span className="sm:hidden">toma de decisiones informadas</span>. Desarrollamos soluciones de BI que permiten a las empresas <span className="hidden sm:inline-block sm:font-bold">recopilar, analizar y visualizar datos de manera efectiva</span><span className="sm:hidden">recopilar, analizar y visualizar datos de manera efectiva</span>. Desde dashboards interactivos hasta informes detallados, nuestras soluciones de BI transforman datos en información accionable, permitiendo a los líderes empresariales tomar decisiones estratégicas con confianza. Utilizamos herramientas de análisis avanzadas para <span className="hidden sm:inline-block sm:font-bold">identificar patrones, tendencias y oportunidades, ayudando a las empresas a maximizar su rendimiento y alcanzar sus objetivos</span><span className="sm:hidden">identificar patrones, tendencias y oportunidades, ayudando a las empresas a maximizar su rendimiento y alcanzar sus objetivos</span>.
        </p>
      </div>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4  rounded-lg p-4 mt-4">
      <div className="flex justify-center">
        <img src={leftImage} alt="Flowmaster Automation Image" className="rounded-lg"/>
      </div>
      <div className="flex flex-col items-center sm:items-start">
      <h2 className="text-3xl text-black w-full sm:w-32 px-8 font-bold text-center">Flowmaster</h2> 
      <span className="bg-blue-700 text-white px-8 py-1 rounded-2xl font-bold text-3xl">Automation</span>
      <p className="sm:mt-2 mt-6 text-black text-xs sm:text-base leading-4 sm:leading-8 text-center sm:text-justify">
        La automatización de procesos es clave para <span className="hidden sm:inline-block sm:font-bold">mejorar la eficiencia y reducir los costos operativos</span><span className="sm:hidden">mejorar la eficiencia y reducir los costos operativos</span>. En QRT, utilizamos tecnologías avanzadas para <span className="hidden sm:inline-block sm:font-bold">automatizar tareas repetitivas y complejas</span><span className="sm:hidden">automatizar tareas repetitivas y complejas</span>, liberando a las personas para que se enfoquen en actividades de mayor valor. Desarrollamos soluciones de automatización personalizadas que integran sistemas y procesos, <span className="hidden sm:inline-block sm:font-bold">optimizando el flujo de trabajo y mejorando la precisión y la velocidad en la ejecución de tareas críticas</span><span className="sm:hidden">optimizando el flujo de trabajo y mejorando la precisión y la velocidad en la ejecución de tareas críticas</span>. Desde la automatización de procesos administrativos hasta la integración de sistemas de producción, nuestras soluciones están diseñadas para transformar la manera en que las empresas operan.
      </p>
      </div>
    </div>
  </div>
  )
}
export default DataVision
