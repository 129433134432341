import React from 'react';  
import cloudNexusVideo from '../videos/cloud_nexus.jpeg';  

const CloudNexus = () => {  
  return (  
    <div className="relative min-h-[80vh] py-12">  
      {/* Degradado superior */}  
      <div className="absolute top-0 left-0 w-full h-28 bg-gradient-to-b from-white to-transparent z-20"></div>  

      <img
  src={cloudNexusVideo}
  alt="Cloud Nexus Background"
  className="absolute top-0 left-0 w-full h-full object-cover"
/>

      <div className="relative flex flex-col lg:flex-row justify-center items-center h-full z-30 max-w-6xl mx-auto text-center sm:text-left px-8">  
        <h1 className="text-4xl sm:text-6xl lg:text-8xl font-bold text-start sm:mr-10 mb-8 lg:mb-0">  
          <span className="text-black">Cloud</span>{' '}  
          <span className="relative inline-block">  
            <span className="absolute inset-x-0 bottom-3 lg:bottom-14 bg-blue-700 h-3 transform translate-y-1/2"></span>  
            <span className="relative text-white transform lg:bottom-10">Nexus</span>  
          </span>  
        </h1>  
        <p className="text-sm sm:text-base text-black text-justify sm:leading-8">  
          En QRT, ayudamos a las empresas a{' '}  
          <strong>migrar, gestionar y optimizar sus operaciones en la nube</strong>. Ofrecemos  
          soluciones cloud que incluyen{' '}  
          <strong>  
            desde la planificación de la migración hasta la implementación y gestión continua  
          </strong>  
          . Trabajamos con las principales plataformas de nube, como{' '}  
          <strong>Google Cloud, AWS y Microsoft Azure</strong>, para ofrecer soluciones escalables,  
          seguras y adaptadas a las necesidades específicas de cada cliente. Nuestras soluciones en  
          la nube permiten a las empresas{' '}  
          <strong>  
            mejorar la flexibilidad, reducir costos de infraestructura y aumentar la capacidad de  
            innovación  
          </strong>  
          .  
        </p>  
      </div>  
    </div>  
  );  
};  

export default CloudNexus;