import React from 'react';
import wasap from '../images/wasap.svg';
import qrtLogo from '../images/favicon_QRT_white.png';

const Footer = () => {
  return (
    <div className="bg-[#0B0D2C] text-white py-8  items-center flex flex-col">
      <div className="container mx-auto flex flex-col md:flex-row items-center sm:items-start justify-between pt-10 px-4 space-y-8 md:space-y-0">
        <div className="flex flex-col items-start mb-6 md:mb-0">
          <img src={qrtLogo} alt="Logo" className="h-28"/>
        </div>
        <div className="flex flex-col items-center sm:items-start mb-6 md:mb-0">
          <h3 className="font-semibold mb-2">Menu</h3>
          <ul className="space-y-2 text-center sm:text-left">
            <li><a href="/" className="hover:underline">Inicio</a></li>
            <li><a href="/servicios" className="hover:underline">Servicios</a></li>
            <li><a href="/nosotros" className="hover:underline">Nosotros</a></li>
          </ul>
        </div>
        <div className="flex flex-col items-center sm:items-start mb-6 md:mb-0">
          <h3 className="font-semibold mb-2">Contacto</h3>
          <div className="text-center sm:text-left">
            <p className="mb-1">nchinchon@qrtsolutions.com</p>
            <p className="mb-1">dnehgme@qrtsolutions.com</p>
            <p>Cerro el Plomo 5420<br/>Las Condes, Santiago.</p>
          </div>
        </div>
        <div className="flex flex-col items-center sm:items-start">
          <h3 className="font-semibold mb-2">Hablemos</h3>
          <button className="bg-transparent flex items-center justify-around w-36 border-yellow-500 border text-yellow-500 px-4 py-2 rounded-xl hover:bg-yellow-600 text-xs font-bold mt-4"

            onClick={() => window.open('https://wa.me/message/2KDGNSCDPMTRC1', '_blank')}
          >

            <img src={wasap} alt="WhatsApp Icon" className="h-6 w-4"/>
            Contactanos
          </button>
          <div className="flex md:ml-9 mt-8">
            <a href="#" className="underline hover:no-underline">Ir Arriba ↑</a>
          </div>
        </div>
      </div>
      <div className="bg-trasparent text-white text-center py-2 mt-8">
        <p className="text-xs">© QRT Solutions 2024 Todos los derechos reservados.</p>
      </div>
    </div>
  );
}

export default Footer;
