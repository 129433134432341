import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContacts = async () => {
      const token = localStorage.getItem("authToken");
      const response = await fetch("https://apis.qrtsolutions.com/users-api/clients/contacts", {

        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });

      if (response.ok) {
        const data = await response.json();
        setContacts(data);
      } else {
        console.error("Error al obtener los contactos");
      }
    };

    fetchContacts();
  }, []);

  const handleRowClick = (contactId) => {
    navigate(`/contact-details/${contactId}`); // Redirige a la vista de detalle del contacto
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold text-center text-gray-800">Lista de Contactos</h1>
      <table className="table-auto w-full mt-6 border-collapse">
        <thead>
          <tr className="bg-gray-800 text-white">
            <th className="px-4 py-3 border-b-2 border-gray-200">Nombre</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Correo</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Empresa</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Teléfono</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Tipo de Consulta</th>
            <th className="px-4 py-3 border-b-2 border-gray-200">Mensaje</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact, index) => (
            <tr
              key={contact.id}
              onClick={() => handleRowClick(contact.id)}
              className={`cursor-pointer hover:bg-gray-100 ${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
            >
              <td className="px-4 py-3 text-gray-700 border-b">{contact.name}</td>
              <td className="px-4 py-3 text-gray-700 border-b">{contact.email}</td>
              <td className="px-4 py-3 text-gray-700 border-b">{contact.company}</td>
              <td className="px-4 py-3 text-gray-700 border-b">{contact.phone}</td>
              <td className="px-4 py-3 text-gray-700 border-b">{contact.query_type}</td>
              <td className="px-4 py-3 text-gray-700 border-b">{contact.message}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContactList;
